import React from "react"

import {
  StaticScrollTextContainer,
  StaticScrollText,
} from "../styles/StaticScrollHeader.styled"

const StaticScrollHeader = ({ component }) => (
  <StaticScrollTextContainer>
    <StaticScrollText lg={9} md={9}>
      <h2 className="heading">{component?.heading}</h2>
      <div
        className="subheading"
        dangerouslySetInnerHTML={{ __html: component?.subhead }}
      />
    </StaticScrollText>
  </StaticScrollTextContainer>
)

export default StaticScrollHeader
