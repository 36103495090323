import React, { useCallback } from "react"
import { Link } from "react-scroll"

import {
  StaticScrollWrapper,
  SelectorButtonWrapper,
} from "../styles/StaticScrollNav.styled"

import NavIcon from "./NavIcon"

const StaticScrollNav = ({ component, setScrollIndex, scrollIndex }) => {
  const items = component?.scrollItems

  const handleChangeTab = useCallback((e, index) => {
    e.preventDefault()
    setScrollIndex(index)
  }, [])

  return (
    <StaticScrollWrapper>
      <SelectorButtonWrapper lg={12} md={12} gap={4} className="selection-tabs">
        {items?.map((item, idx) => (
          <Link
            activeClass="active"
            to={`scroll-container-${idx}`}
            smooth={true}
            offset={-100}
            duration={500}
            className={`tab-button ${
              idx === scrollIndex ? "active" : ""
            } scroll-to-link`}
            key={item?.id}
            onClick={e => handleChangeTab(e, idx)}
          >
            <NavIcon item={item} id={idx} scrollIndex={scrollIndex} />
            {item?.navLabel}
          </Link>
        ))}
      </SelectorButtonWrapper>
    </StaticScrollWrapper>
  )
}
export default StaticScrollNav
