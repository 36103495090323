import React from "react"

import Icon from "../../Icon/Icon"
import { IconWrapper } from "../styles/NavIcon.styled"

const NavIcon = ({ item, id, scrollIndex }) => (
  <IconWrapper>
    <Icon
      id={item?.navIcon}
      classes={`${id === scrollIndex ? "active" : ""}`}
      theme="grayBlue"
      size="44"
      active
    />
  </IconWrapper>
)

export default NavIcon
