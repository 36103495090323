import { Row } from "react-bootstrap"
import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const ScrollRow = styled(Row)`
  justify-content: center;
  align-items: center;
  font-family: "Avenir";
  font-style: normal;
  height: 80vh;
  @media (max-width: 1280px) {
    height: 85vh;
  }
  .eyebrow {
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${color.gray[400]};
    margin-bottom: 16px;
    @media (max-width: 575.98px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .scroll-tab-header {
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: ${color.oxfordBlue[900]};
    margin-bottom: 16px;
  }
  .description {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: ${color.copy[700]};
    margin-bottom: 16px;
    @media (max-width: 991.98px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .learn-more {
    position: relative;
    text-decoration: none;
    font-weight: 800;
    line-height: 24px;
    color: ${color.primary[700]};
    padding-right: 15px;
    &:before,
    &:after {
      border-right: 2px solid;
      content: "";
      display: block;
      height: 8px;
      margin-top: -6px;
      position: absolute;
      transform: rotate(135deg);
      right: 0px;
      top: 50%;
      width: 0;
    }
    :after {
      margin-top: -1px;
      transform: rotate(45deg);
    }
  }
`
