import styled from "styled-components"

export const ImgWrapper = styled.div`
  height: 800px;
  width: 100%;
  position: relative;
  display: ${props => (props.scrollIndex === props.id ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  animation: ${props => (props.scrollIndex === props.id ? "fadeIn 1.5s" : "unset")};
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0
  }
  img {
    margin-left: 30px;
    max-width: 400px;
  }
  @media (max-width: 1366px) {
    height: 550px;
    img {
      max-height: 550px;
    }
  }
`
