import React, { useState, useRef, useEffect, useContext } from "react"

import { HeaderContext } from "../../contexts/HeaderProvider"

import MobileScrollCard from "./components/MobileScrollCard"
import StaticScrollCardContainer from "./components/StaticScrollCardContainer"
import StaticScrollHeader from "./components/StaticScrollHeader"
import StaticScrollNav from "./components/StaticScrollNav"
import { NavWrapper } from "./styles/StaticScrollSection.styled"
import { addNavEventListeners } from "./utils/addNavEventListeners"

const StaticScrollSection = ({ component }) => {
  const navRef = useRef(null)
  const [scrollIndex, setScrollIndex] = useState(0)
  const [navPositionTop, setNavPositionTop] = useState(null)
  const [navPositionBtm, setNavPositionBtm] = useState(null)
  const [mobileActive, setMobileActive] = useState(false)

  const { isMobileNavHidden } = useContext(HeaderContext)

  const getScrollPosition = (id) => {
    const rect = document.getElementById(id).getBoundingClientRect()
    const top = Math.round(rect.top)
    const bottom = Math.round(rect.bottom)
    setNavPositionTop(top)
    setNavPositionBtm(bottom)
  }

  useEffect(() => {
    addNavEventListeners(navRef, getScrollPosition, setMobileActive)
  }, [])

  return (
    <div className="static-selector">
      <StaticScrollHeader component={component} />
      <NavWrapper
        id="nav-wrapper"
        ref={navRef}
        navPositionTop={navPositionTop}
        isMobileNavHidden={isMobileNavHidden}
      >
        <StaticScrollNav
          component={component}
          navRef={navRef}
          scrollIndex={scrollIndex}
          setScrollIndex={setScrollIndex}
          mobileActive={mobileActive}
          setMobileActive={mobileActive}
        />
      </NavWrapper>
      {!mobileActive && (
        <StaticScrollCardContainer
          component={component}
          scrollIndex={scrollIndex}
          setScrollIndex={setScrollIndex}
          navPositionTop={navPositionTop}
          navPositionBtm={navPositionBtm}
        />
      )}
      {mobileActive && (
        <MobileScrollCard
          scrollIndex={scrollIndex}
          component={component}
          navPositionBtm={navPositionBtm}
          setScrollIndex={setScrollIndex}
        />
      )}
    </div>
  )
}

export default StaticScrollSection
