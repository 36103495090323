import styled from "styled-components"

import { color } from "../../../atoms/colors"

const stickyNav = (props) => {
  if (props.isMobileNavHidden === true) {
    return "0px"
  }
  if (props.navPositionTop <= 155 && props.isMobileNavHidden === false) {
    return "125px"
  }
  return "unset"
}

export const NavWrapper = styled.div`
  position: ${(props) => (props.navPositionTop <= 155 ? "sticky" : "unset")};
  top: ${(props) => stickyNav(props)};
  z-index: ${(props) => (props.navPositionTop <= 155 ? "90" : "unset")};
  background: ${color.common.white};
  transition: top 0.1s ease-in-out 0.2s;
`
