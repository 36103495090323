import React, { useState, useEffect } from "react"

import { CreateLottie } from "../../../utils/CreateLottie"
import Img from "../../../utils/OptimizedImage"
import fetchLottie from "../../../utils/fetchLottie"
import { ImgWrapper } from "../styles/StickyImage.styled"

const StickyImage = ({ item, id, scrollIndex }) => {
  const [animation, setAnimation] = useState()
  const [mediaType, setMediaType] = useState("")
  const [imageUrl, setImageUrl] = useState("")

  useEffect(() => {
    if (item?.lottie?.lottie?.url) {
      setMediaType("lottie")
      fetchLottie(item?.lottie?.lottie?.url, setAnimation)
    } else {
      setMediaType("img")
      setImageUrl(item?.image?.url)
    }
  }, [])

  const CreateMediaWrapper = formatType => {
    switch (formatType) {
      case "lottie":
        return (
          <>
            <CreateLottie
              animData={animation}
              id={id}
              scrollIndex={scrollIndex}
            />
          </>
        )
      default:
        return (
          <>
            <Img src={imageUrl} alt={item?.image?.alt || item?.heading} />
          </>
        )
    }
  }

  return (
    <ImgWrapper id={id} scrollIndex={scrollIndex}>
      {CreateMediaWrapper(mediaType)}
    </ImgWrapper>
  )
}

export default StickyImage
