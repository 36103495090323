import { Col, Container } from "react-bootstrap"
import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const StaticScrollTextContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  padding-top: 96px;
  @media (max-width: 991.98px) {
    padding-top: 56px;
  }
  @media (max-width: 767.98px) {
    padding-top: 40px;
  }
  .heading {
    font-family: Avenir;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: center;
    color: ${color.oxfordBlue[900]};
    margin-bottom: 24px;
    @media (max-width: 991.98px) {
      font-size: 36px;
      line-height: 44px;
    }
    @media (max-width: 767.98px) {
      font-size: 30px;
      line-height: 38px;
    }
    @media (max-width: 575.98px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .subheading {
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: ${color.copy[700]};
    @media (max-width: 991.98px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const StaticScrollText = styled(Col)`
  text-align: center;
  justify-content: center;
`
