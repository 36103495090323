import React, { useRef, useEffect } from "react"
import { Col } from "react-bootstrap"

import { ScrollRow, ScrollCol } from "../styles/MobileScrollCard.styled"
import addMobileScrollCardListeners from "../utils/addMobileScrollCardListeners"

import MobileImage from "./MobileImage"

const MobileScrollCardContent = ({
  item,
  idx,
  scrollIndex,
  setScrollIndex,
  navPositionBtm,
}) => {
  const containerRef = useRef()
  const cardIndex = idx

  const getScrollPosition = idx => {
    const target = document?.getElementById(idx)
    const rect = target?.getBoundingClientRect()
    const top = Math.round(rect?.top)
    if (top <= navPositionBtm + 462 && top >= navPositionBtm + 96) {
      setScrollIndex(cardIndex)
    }
  }

  useEffect(() => {
    addMobileScrollCardListeners(idx, containerRef, getScrollPosition)
  }, [])

  return (
    <ScrollRow
      key={idx}
      id={`scroll-container-${idx}`}
      ref={containerRef.current}
      className="justify-content-md-center"
    >
      <Col className="text-content" md={12}>
        <p className="eyebrow">{item?.eyebrow}</p>
        <h2 className="scroll-tab-header">{item?.heading}</h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: item?.subhead }}
        />
        {item?.callToAction?.url && (
          <a className="learn-more" href={item?.callToAction?.url}>
            {item?.callToAction?.label}
          </a>
        )}
      </Col>
      <ScrollCol>
        <MobileImage item={item} scrollIndex={scrollIndex} idx={idx} />
      </ScrollCol>
    </ScrollRow>
  )
}

export default MobileScrollCardContent
