import React, { useEffect, useState, useMemo } from "react"
import { Row, Col } from "react-bootstrap"

import { ScrollContainer, StickyCol } from "../styles/StaticScrollCardContainer"

import StaticScrollCard from "./StaticScrollCard"
import StickyImage from "./StickyImage"

const StaticScrollCardContainer = ({
  component,
  scrollIndex,
  setScrollIndex,
  navPositionTop,
  navPositionBtm,
}) => {
  const [scrollItems, setScrollItems] = useState([])

  useEffect(() => {
    const items = component?.scrollItems
    setScrollItems(items)
  }, [])

  const scrollContent = useMemo(
    () =>
      scrollItems &&
      scrollItems?.map((item, idx) => (
        <>
          <StaticScrollCard
            item={item}
            id={idx}
            key={idx}
            navPositionTop={navPositionTop}
            navPositionBtm={navPositionBtm}
            scrollIndex={scrollIndex}
            setScrollIndex={setScrollIndex}
          />
        </>
      )),
    [navPositionTop, navPositionBtm, scrollIndex, scrollItems]
  )

  const scrollSticky = useMemo(
    () =>
      scrollItems &&
      scrollItems?.map((item, idx) => (
        <StickyImage key={idx} id={idx} item={item} scrollIndex={scrollIndex} />
      )),
    [navPositionTop, navPositionBtm, scrollIndex, scrollItems]
  )

  return (
    <ScrollContainer id="scrollContainer">
      <Row>
        <Col>{scrollContent}</Col>
        <StickyCol>{scrollSticky}</StickyCol>
      </Row>
    </ScrollContainer>
  )
}
export default StaticScrollCardContainer
