import styled from "styled-components"

export const LottieWrapper = styled.div`
  height: 500px;
  width: 100%;
  position: relative;
  margin: 32px 0px;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0
  }
`
export const ImgWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 32px 0px;
  img {
    width: 100%;
    height: auto;
  }
`