const addMobileScrollCardListeners = (id, containerRef, getScrollPosition) => {
  if (typeof window !== `undefined`) {
    window.addEventListener(
      "scroll",
      () => {
        getScrollPosition(`scroll-container-${id}`, containerRef)
      },
      { passive: true }
    )
    return () => {
      window.removeEventListener("scroll", () =>
        getScrollPosition(`scroll-container-${id}`, containerRef)
      )
    }
  }
}

export default addMobileScrollCardListeners
