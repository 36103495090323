export const addNavEventListeners = (
  navRef,
  scrollPosition,
  setMobileActive
) => {
  if (typeof window !== `undefined`) {
    window.addEventListener(
      "scroll",
      () => scrollPosition("nav-wrapper", navRef),
      { passive: true }
    )

    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth > 700) {
          setMobileActive(false)
        } else if (window.innerWidth <= 700) {
          setMobileActive(true)
          window.removeEventListener("scroll", () =>
            scrollPosition("nav-wrapper", navRef)
          )
        }
      },
      { passive: true }
    )

    if (window.innerWidth > 700) {
      setMobileActive(false)
    } else if (window.innerWidth <= 700) {
      setMobileActive(true)
      window.removeEventListener("scroll", () =>
        scrollPosition("nav-wrapper", navRef)
      )
    }
  }
}

export default addNavEventListeners
