import { useLottie } from "lottie-react"
import { useEffect } from "react"

export const CreateLottie = ({ animData, id, scrollIndex }) => {
  const lottieOpts = {
    autoplay: false,
    delay: 0.2,
    loop: true,
    animationData: animData,
  }
  const CardLottie = useLottie(lottieOpts)
  useEffect(() => {
    if (id === scrollIndex) {
      CardLottie.play()
    } else {
      CardLottie.stop()
    }
  }, [animData, scrollIndex])

  const { View } = CardLottie
  return View
}
