import React, { useState, useEffect } from "react"

import { CreateLottie } from "../../../utils/CreateLottie"
import Img from "../../../utils/OptimizedImage"
import fetchLottie from "../../../utils/fetchLottie"
import { ImgWrapper, LottieWrapper } from "../styles/MobileImage.styled"

const MobileImage = ({ item, scrollIndex, idx }) => {
  const [animation, setAnimation] = useState()
  const [mediaType, setMediaType] = useState("")
  const [imageUrl, setImageUrl] = useState("")

  useEffect(() => {
    if (item?.lottie?.lottie?.url) {
      setMediaType("lottie")
      fetchLottie(item?.lottie?.lottie?.url, setAnimation)
    } else {
      setMediaType("img")
      setImageUrl(item?.image?.url)
    }
  }, [])

  const CreateMediaWrapper = formatType => {
    switch (formatType) {
      case "lottie":
        return (
          <LottieWrapper id={idx} scrollIndex={scrollIndex}>
            <CreateLottie
              animData={animation}
              id={idx}
              scrollIndex={scrollIndex}
            />
          </LottieWrapper>
        )
      default:
        return (
          <ImgWrapper>
            <Img src={imageUrl} alt={item?.image?.alt || item?.heading} />
          </ImgWrapper>
        )
    }
  }

  return <>{CreateMediaWrapper(mediaType)}</>
}

export default MobileImage
