import React, { useRef, useEffect } from "react"
import { Col } from "react-bootstrap"

import { ScrollRow } from "../styles/StaticScrollCard.styled"
import addStaticScrollCardListeners from "../utils/addStaticScrollCardListeners"

const StaticScrollCard = ({ id, item, setScrollIndex, navPositionBtm }) => {
  const containerRef = useRef()
  const cardIndex = id

  const getScrollPosition = id => {
    const target = document?.getElementById(id)
    const rect = target?.getBoundingClientRect()
    const top = Math.round(rect?.top)
    if (top <= navPositionBtm + 462 && top >= navPositionBtm + 96) {
      setScrollIndex(cardIndex)
    }
  }

  useEffect(() => {
    addStaticScrollCardListeners(id, containerRef, getScrollPosition)
  }, [])

  return (
    <ScrollRow
      id={`scroll-container-${id}`}
      ref={containerRef}
      className="justify-content-md-center"
    >
      <Col md={12}>
        <p className="eyebrow">{item?.eyebrow}</p>
        <h2 className="scroll-tab-header">{item?.heading}</h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: item?.subhead }}
        />
        {item?.callToAction?.url && (
          <a href={item?.callToAction?.url} className="learn-more">
            {item?.callToAction?.label}
          </a>
        )}
      </Col>
    </ScrollRow>
  )
}

export default StaticScrollCard
