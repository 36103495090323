import React from "react"

import MobileScrollCardContent from "./MobileScrollCardContent"

const MobileScrollCard = ({
  component,
  scrollIndex,
  setScrollIndex,
  navPositionBtm,
}) => (
  <>
    {component?.scrollItems.map((item, idx) => {
      return (
        <MobileScrollCardContent
          key={idx}
          item={item}
          idx={idx}
          scrollIndex={scrollIndex}
          setScrollIndex={setScrollIndex}
          navPositionBtm={navPositionBtm}
        />
      )
    })}
  </>
)

export default MobileScrollCard
