import styled from "styled-components"
import { Container, Col } from "react-bootstrap"

export const ScrollContainer = styled(Container)`
  @media (max-width: 992px) {
    max-width: 770px !important;
  }
`

export const StickyCol = styled(Col)`
  position: sticky;
  top: 20vh;
  align-self: flex-start;
  @media (max-width: 1366px) {
    top: 28vh;
  }
`
