import { Row } from "react-bootstrap"
import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const StaticScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background: ${color.common.white};
  box-shadow: 0px 12px 16px -4px rgba(1, 85, 252, 0.1),
    0px 4px 6px -2px rgba(21, 91, 230, 0.05);
  border-radius: 12px;
  padding-top: 24px;
`

export const SelectorButtonWrapper = styled(Row)`
  margin: 0 15px;
  max-width: 960px;
  gap: 2rem;
  justify-content: space-between;
  @media (max-width: 767.98px) {
    max-width: 506px;
    justify-content: space-around;
    gap: 0;
  }
  .tab-button {
    cursor: pointer;
    border-bottom: 2px solid ${color.common.transparent};
    background: white;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${color.oxfordBlue[900]};
    display: flex;
    transition: all 0.3s ease-in-out;
    text-align: center;
    svg {
      padding-bottom: 8px;
    }
    @media (max-width: 991.98px) {
      font-size: 16px;
      line-height: 24px;
      max-width: 130px;
    }
    @media (max-width: 767.98px) {
      max-width: inherit;
    }
  }

  .tab-button.active {
    border-bottom: 2px solid rgb(0, 127, 195);
    margin-bottom: 0;
    color: ${color.common.linkBlue};
  }

  .scroll-to-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      text-decoration: none;
    }
  }
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
