import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 576px) {
    display: none;
  }
  &.icon {
    svg {
      rect {
        transition: all 0.5s ease-in-out;
        fill: ${color.gray[200]};
      }
      path {
        transition: all 0.5s ease-in-out;
        stroke: ${color.copy[600]};
        fill: ${color.gray[200]};
      }
      .line {
        transition: all 0.5s ease-in-out;
        stroke: ${color.copy[600]};
        fill: ${color.copy[600]};
      }
      #outline {
        transition: all 0.5s ease-in-out;
        stroke: ${color.gray[200]};
        fill: none;
      }
    }
  }
  &.icon.active {
    svg {
      rect {
        fill: ${color.primary[700]};
      }
      path {
        stroke: ${color.common.white};
        fill: ${color.primary[700]};
      }
      .line {
        stroke: ${color.common.white};
        fill: ${color.common.white};
      }
      #outline {
        stroke: ${color.common.linkBlue};
        fill: none;
      }
    }
  }
`
