import styled, { css } from "styled-components"

import { borderIconTheme } from "../utils/themes"

export const BorderIcon = styled.svg`
  use {
    transition: fill ease 350ms, stroke ease 350ms, color ease 350ms;
    ${(props) =>
      props.width &&
      css`
        width: ${props.width}px;
      `}
    ${(props) =>
      props.height &&
      css`
        height: ${props.height}px;
      `}
    ${(props) => borderIconTheme?.fillColor[props.theme]};
    ${(props) => borderIconTheme?.strokeColor[props.theme]};
    ${(props) => borderIconTheme?.color[props.theme]};
    &:hover {
      ${(props) =>
        props.active && borderIconTheme?.fillActiveColor[props.theme]};
      ${(props) =>
        props.active && borderIconTheme?.strokeActiveColor[props.theme]};
      ${(props) => props.active && borderIconTheme?.activeColor[props.theme]};
      ${(props) => props.active && borderIconTheme?.pointer[props.theme]};
    }
  }
  &.active use {
    ${(props) => props.active && borderIconTheme?.fillActiveColor[props.theme]};
    ${(props) =>
      props.active && borderIconTheme?.strokeActiveColor[props.theme]};
    ${(props) => props.active && borderIconTheme?.activeColor[props.theme]};
    ${(props) => props.active && borderIconTheme?.pointer[props.theme]};
  }
`
