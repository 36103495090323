import { color } from "../../../atoms/colors"

export const borderIconTheme = {
  fillColor: {
    dark: `fill: ${color.gray[600]}`,
    grayBlue: `fill: ${color.gray[200]}`,
  },
  strokeColor: {
    dark: `stroke: ${color.common.white}`,
    grayBlue: `stroke: ${color.copy[600]}`,
  },
  color: {
    dark: `color: ${color.gray[700]}`,
    grayBlue: `color: ${color.gray[200]}`,
  },
  fillActiveColor: {
    grayBlue: `fill: ${color.primary[700]}`,
  },
  strokeActiveColor: {
    grayBlue: `stroke: ${color.common.white}`,
  },
  activeColor: {
    grayBlue: `color: ${color.common.linkBlue}`,
  },
  pointer: {
    grayBlue: `cursor: pointer`,
  },
}
